<template>
<div class="card-inner blog" id="blog-card">
				<div class="card-wrap">

					<!-- Blog -->
					<div class="content blog">

						<!-- title -->
						<div class="title">
							<span >Blog</span>
						</div>

						<div v-if="blogItems == ''">
							<p>
								Loading blog post..
							</p>
						</div>
						<!-- content -->
						<div class="row border-line-v">
							<!-- blog item -->
							<div v-for="post in blogItems" v-bind:key="post.guid" class="col col-d-6 col-t-6 col-m-12">
								<div class="box-item">
									<div class="image">
										<a :href="post.link" target="_blank" >
											<img :src="post.image" :alt="post.title" />
											<span class="info">
												<span class="ion ion-document-text"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a :href="post.link" target="_blank" >
											<span class="date">{{post.pubDate}}</span>
										</a>
										<a :href="post.link" target="_blank" class="name">{{post.title}}</a>
										<div class="text">
											<p>{{post.contentSnippet.substring(0,post.contentSnippet.lastIndexOf(" ",150)) + "..."}}</p>
										</div>
									</div>
								</div>
							</div>
							
							<div class="clear"></div>
						</div>

						<div class="pager">
							<nav class="navigation pagination">
								<div class="nav-links">
									<!-- <span class="page-numbers current">1</span>
									<a class="page-numbers" href="#">2</a> -->
									<a class="next page-numbers" target="_blank" href="https://blog.nadhir.net">View More</a>
								</div>
							</nav>
						</div>

					</div>

				</div>
			</div>
</template>

            
<script>
const Parser = require('rss-parser');
const parser = new Parser();

let url = 'https://cors-proxy.nadhir.net/http://blog.nadhir.net/feeds/posts/default?alt=rss';

export default {
  name: "Blog",
  props: {
  },
  data: function () {
    return {
      blogItems: ''
    }
  },
  mounted() {
	parser.parseURL(url)
	.then(feed => {
		this.blogItems = feed.items.slice(0, 4).map((element) => {
			var elem = document.createElement('div');
			elem.innerHTML = element.content;
			let i = 0;
			let images = elem.getElementsByTagName("img");
			let image = ''
			do{
				image = elem.getElementsByTagName("img")[i] != undefined 
						&& elem.getElementsByTagName("img")[i].src.indexOf("googleusercontent.com") != -1 
						&& elem.getElementsByTagName("img")[i].height > 100
				? elem.getElementsByTagName("img")[i].src 
				: 'images/blog/blog1.jpg'
			} while(i++
					&& elem.getElementsByTagName("img")[i] != undefined 
					&& elem.getElementsByTagName("img")[i].src.indexOf("googleusercontent.com") == -1
					&& elem.getElementsByTagName("img")[i].height <= 100
					)
			element.image = image;
			return element;
		});
	})
	.catch(e => {
		console.log(e);
	});
  },
};
</script>