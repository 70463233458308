<template>
<div class="card-inner" id="works-card">
				<div class="card-wrap">

					<!--
						Works
					-->
					<div class="content works">

						<!-- title -->
						<div class="title">Recent Works</div>

						<!-- filters -->
						<div class="filter-menu filter-button-group">
							<div class="f_btn active">
								<label><input type="radio" name="fl_radio" value="grid-item" />All</label>
							</div>
							<div class="f_btn">
								<label><input type="radio" name="fl_radio" value="web" />Web Apps</label>
							</div>
							<div class="f_btn">
								<label><input type="radio" name="fl_radio" value="ai" />AI Apps</label>
							</div>
							<div class="f_btn">
								<label><input type="radio" name="fl_radio" value="mobile" />Mobile Apps</label>
							</div>
							<div class="f_btn">
								<label><input type="radio" name="fl_radio" value="gallery" />Gallery</label>
							</div>
						</div>

						<!-- content -->
						<div class="row grid-items border-line-v">

							<!-- work item web -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item web border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-2" class="has-popup-media">
											<img src="images/works/work2.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-2" class="name has-popup-media">VR Showcase</a>
										<div class="category">Web Apps</div>
									</div>
									<div id="popup-2" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work2.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>VR Showcase</h1>						
													<div class="blog-detail">Web Apps</div>
													<div class="blog-content">
														<p>
															Virtual Reality website build for a Virtual Showcase. User could feel a real showcase by using their device.
														</p>
														<blockquote>
															Best view on mobile device, a perfect combination using VR BOX.
														</blockquote>
														<p>
															Real view of showcase. Interactive website, user could click and direct buy from page.
														</p>
														<p>
															Main feature:
														</p>
														<ul class="list-style">
															<li>360 Virtual Reality, Gyroscope Supported.</li>
															<li>Interactive Virtual Reality.</li>
															<li>Details and buy items directly in page.</li>
															<li>Management items in showcase.</li>
															<li>Deploy on Cloud, using Heroku and Google Cloud.</li>
														</ul>
														<p>
															Find it out below<br>
															Showcase site: <a href="https://vr-showcase-fe-customer.herokuapp.com/">here</a><br>
															Admin site: <a href="https://vr-showcase-fe-admin.herokuapp.com/">here</a><br>
														</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item ai -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item ai border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-8" class="has-popup-media">
											<img src="images/works/work8.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-8" class="name has-popup-media">Machine Learning Based - Intrusion Detection System</a>
										<div class="category">AI Apps</div>
									</div>
									<div id="popup-8" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work8.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>Machine Learning Based - Intrusion Detection System</h1>						
													<div class="blog-detail">AI Apps</div>
													<div class="blog-content">
														<p>
															This is an IDS with Machine Learning Based.
														</p>
														<p>
															This IDS (Intrusion Detection System) used to detect DDoS Attack in Software-Defined Network with utilizing sFlow protocol.
														</p>
														<p>
															I made a model to predict DDoS Attack in SDN.
															Model trained using CSE-CIC-IDS-2018 dataset.
															Realtime Traffic data in SDN using sFlow.
														</p>
														<p>
															Feature and Tech used in this project:.
														</p>
														<ul class="list-style">
															<li>Django Framework (Python).</li>
															<li>API (WebSocket and HTTP).</li>
															<li>Realtime Attack Alert.</li>
															<li>Full async background process (Django-Channels).</li>
															<li>Scikit-learn for classification.</li>
														</ul>
														<p>
															Find it out below
														</p>
													</div>
													<a href="https://github.com/nadhirfr/rf-ids" class="button">
														<span class="text">View Project</span>
														<span class="arrow"></span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item web -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item web border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-9" class="has-popup-media">
											<img src="images/works/work9.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-9" class="name has-popup-media">PLN Virtual Aset Locator</a>
										<div class="category">Web Apps</div>
									</div>
									<div id="popup-9" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work9.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>PLN Virtual Aset Locator</h1>						
													<div class="blog-detail">Web Apps</div>
													<div class="blog-content">
														<p>
															Internal <a href="https://www.pln.co.id/">PLN</a> Application. This application used to manage PLN Asset, including 360 picture view of asset and location in maps.
														</p>
														<p>
															Feature and Tech used in this project:.
														</p>
														<ul class="list-style">
															<li>Using PlayFramework (Java - Scala)</li>
															<li>Oracle Database.</li>
															<li>WebService API for Mobile Apps.</li>
															<li>Dashboard Web Admin for management.</li>
															<li>Geo location of assets.</li>
															<li>360 view of asset.</li>
														</ul>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item web -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item web border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-6" class="has-popup-media">
											<img src="images/works/work6.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-6" class="name has-popup-media">SIPUT: Sistem Informasi Keuangan RT</a>
										<div class="category">Web Apps</div>
									</div>
									<div id="popup-6" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work6.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>SIPUT: Sistem Informasi Keuangan RT</h1>						
													<div class="blog-detail">Web Apps</div>
													<div class="blog-content">
														<p>
															The Neighborhood Association Financial Management System Application is a desktop-based (Java) software and website (PHP). This app is enabled to manage income and expense funds.
														</p>
														<p>
															Feature used in this project:.
														</p>
														<ul class="list-style">
															<li>JavaFX UI + CodeIgniter</li>
															<li>MySQL Database.</li>
															<li>Record Transaction Data (Contribution and Expenditure).</li>
															<li>Calculating Transaction Data (Calculating Debt Dues, etc.).</li>
															<li>User Management.</li>
														</ul>
														<p>
															Find the apss demo below:
														</p>
													</div>
													<a href="https://www.youtube.com/watch?v=7MjUEeuJcNc" class="button">
														<span class="text">View Project</span>
														<span class="arrow"></span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item web -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item web border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-3" class="has-popup-media">
											<img src="images/works/work3.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-3" class="name has-popup-media">Computer Based Test Examination</a>
										<div class="category">Web Apps</div>
									</div>
									<div id="popup-3" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work3.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>Computer Based Test Examination</h1>						
													<div class="blog-detail">Web Apps</div>
													<div class="blog-content">
														<p>
															Internal Computer Based Test application. This application used as an online assesment of the students.
														</p>
														<blockquote>
															A simle CBT test for a students.
														</blockquote>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item mobile -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item mobile border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-4" class="has-popup-media">
											<img src="images/works/work4.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-4" class="name has-popup-media">JadwalKu</a>
										<div class="category">Mobile Apps</div>
									</div>
									<div id="popup-4" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work4.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>JadwalKu - Jadwal Kuliah</h1>						
													<div class="blog-detail">Mobile Apps</div>
													<div class="blog-content">
														<p>
															JadwalKu (MySchedule) - Lecture Schedule is a lecture schedule application wrapped in timeline view time per day for easier setup and operation.
														</p>
														<p>
															This application is made to facilitate the user (student) in managing the schedule and task.
														</p>
														<p>
															There is a note feature for keeping track of each course assignment, can include images when needed.
														</p>
														<p>
															Reminder notification feature to remind college schedule and task.
														</p>
														<ul class="list-style">
															<li>Timeline schedule.</li>
															<li>Notes and reminder.</li>
															<li>Natievly build with Java</li>
														</ul>
													</div>
													<a href="https://play.google.com/store/apps/details?id=id.nadhir.jadwalku" class="button">
														<span class="text">View Project</span>
														<span class="arrow"></span>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item mobile -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item mobile web border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#popup-7" class="has-popup-media">
											<img src="images/works/work7.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
									</div>
									<div class="desc">
										<a href="#popup-7" class="name has-popup-media">X-Chain | BCA Finhacks 2019</a>
										<div class="category">Mobile Apps</div>
									</div>
									<div id="popup-7" class="popup-box mfp-fade mfp-hide">
										<div class="content">
											<div class="image">
												<img src="images/works/work7.png" alt="">
											</div>
											<div class="desc">
												<div class="post-box">
													<h1>X-Chain | BCA Finhacks 2019</h1>						
													<div class="blog-detail">Mobile Apps</div>
													<div class="blog-content">
														<p>
															Invent an app to deal with inter-eWallet settlement (GoPay, OVO, etc).
														</p>
														<p>
															Hackathon challenge by <a href="https://finhacks.id/">BCA Finhacks</a> to build apps using Blockchain tecnology. 
															We made it, we made transaction between different eWallet possible.
														</p>
														<blockquote>
															Use Ethereum Smart-Contract (Blockchain) as data storage and ledger.
														</blockquote>
														<p>
															Tech used in this project:.
														</p>
														<ul class="list-style">
															<li>NodeJS as Backend.</li>
															<li>VueJS as FrontEnd.</li>
															<li>Ionic Framework as Mobile Apps Platform.</li>
															<li>Ethereum Smart-Contract (Blockchain) as data storage and ledger.</li>
															<li>Redis as cache and in-memory database.</li>
															<li>Microservices deployed on Heroku.</li>
														</ul>
														<p>
															Web version: <a href="https://xchain-fe.herokuapp.com/"> here </a><br>
															Mobile app: <a href="https://play.google.com/store/apps/details?id=com.xchain.xchain.app"> here </a>
														</p>
													</div>
													
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>

							<!-- work item photo -->
							<div class="col col-d-6 col-t-6 col-m-12 grid-item gallery border-line-h">
								<div class="box-item">
									<div class="image">
										<a href="#gallery-1" class="has-popup-gallery">
											<img src="images/works/gallery/project_galley.png" alt="" />
											<span class="info">
												<span class="ion ion-images"></span>
											</span>
										</a>
										<div id="gallery-1" class="mfp-hide">
											<a href="images/works/gallery/xchain-6.png"></a>
											<a href="images/works/gallery/xchain-1.png"></a>
											<a href="images/works/gallery/xchain-2.png"></a>
											<a href="images/works/gallery/xchain-3.png"></a>
											<a href="images/works/gallery/xchain-4.png"></a>
											<a href="images/works/gallery/xchain-5.png"></a>
											<a href="images/works/gallery/elearning-1.png"></a>
											<a href="images/works/gallery/elearning-2.png"></a>
											<a href="images/works/gallery/jadwalku-1.png"></a>
											<a href="images/works/gallery/jadwalku-2.png"></a>
											<a href="images/works/gallery/jadwalku-3.png"></a>
											<a href="images/works/gallery/jadwalku-4.png"></a>
											<a href="images/works/gallery/jadwalku-5.png"></a>
											<a href="images/works/gallery/rf_ids-1.png"></a>
											<a href="images/works/gallery/rf_ids-2.png"></a>
											<a href="images/works/gallery/siput-1.png"></a>
											<a href="images/works/gallery/siput-2.png"></a>
											<a href="images/works/gallery/virtual_aset_locator-1.png"></a>
											<a href="images/works/gallery/vr-showcase-1.png"></a>
											<a href="images/works/gallery/vr-showcase-2.png"></a>
										</div>
									</div>
									<div class="desc">
										<a href="images/works/gallery/project_galley.png" class="name has-popup-image">More Picture of Projects</a>
										<div class="category">Gallery</div>
									</div>
								</div>
							</div>

							<div class="clear"></div>
						</div>

					</div>

				</div>
			</div>
</template>

            
<script>
export default {
  name: "Work",
  props: {
  }
};
</script>