<template>
<div class="card-inner" id="resume-card">
				<div class="card-wrap">

					<!--
						Resume
					-->
					<div class="content resume">

						<!-- title -->
						<div class="title">Resume</div>

						<!-- content -->
						<div class="row">

							<!-- experience -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="resume-title border-line-h">
									<div class="icon"><i class="fa fa-briefcase"></i></div>
									<div class="name">Experience</div>
								</div>
								<div class="resume-items">
									<div class="resume-item border-line-h active">
										<div class="date">2022 - Present</div>
										<div class="name">Technical Lead</div>
										<div class="company">Gameloft SE</div>
										<p>
											Supervising team projects to keep them on track.
											Empowering the dev to work in the best performance.
											Lead and manage the team daily.
											Working with Gameloft Global eCommerce Teams to deliver the products and solve the business needs.
										</p>
									</div>
									<div class="resume-item border-line-h">
										<div class="date">2019 - 2022</div>
										<div class="name">eCommerce Developer</div>
										<div class="company">Gameloft SE</div>
										<p>
											Working with Gameloft Global eCommerce Teams to deliver Gameloft products for all users around the world.
											Supporting biz team to optimize and expand the sales.
										</p>
									</div>
									<div class="resume-item">
										<div class="date">Juli - September 2017</div>
										<div class="name">IP Analyst (Co-op Assistant)</div>
										<div class="company">PT Icon+ - PLN GROUP (Intern)</div>
										<p>
											Monitor the network devices to maintain high availability of services such as IPVPN, Internet, Metro Ethernet, Clear Channel.
											Analyze and handle network faults from Super-Backbone to Lastmile devices.
										</p>
									</div>
								</div>
							</div>

							<!-- education -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="resume-title border-line-h">
									<div class="icon"><i class="fa fa-university"></i></div>
									<div class="name">Education</div>
								</div>
								<div class="resume-items">
									<div class="resume-item border-line-h">
										<div class="date">2020 - 2022</div>
										<div class="name">Gadjah Mada University</div>
										<div class="company">Yogyakarta</div>
										<p>
											Master's Degree in Computer Science
										</p>
									</div>
									<div class="resume-item border-line-h">
										<div class="date">2015 - 2019</div>
										<div class="name">Gadjah Mada University</div>
										<div class="company">Yogyakarta</div>
										<p>
											Bachelor's Degree in Network Engineering
										</p>
									</div>
									<div class="resume-item">
										<div class="date">2011 - 2015</div>
										<div class="name">SMK N 2 Depok (STM Pembangunan)</div>
										<div class="company">Yogyakarta</div>
										<p>
											Vocational School in Computer and Network Engineer
										</p>
									</div>
								</div>
							</div>

							<!-- publication -->
							<div class="col col-d-12 col-t-12 col-m-12 border-line-v">
								<div class="resume-title border-line-h">
									<div class="icon"><i class="fa fa-bookmark"></i></div>
									<div class="name">Publication</div>
								</div>
								<div class="resume-items">
									<div class="resume-item border-line-h active">
										<div class="name"><a href="https://doi.org/10.18517/ijaseit.13.2.17810">XGBoost Classifier for DDOS Attack Detection in Software Defined Network Using sFlow Protocol</a></div>
										<div class="company">April 2023</div>
										<p>
											From a security perspective, Software Defined Network (SDN) separates security concerns into Control Plane and Data Plane. The Control Plane is responsible for managing the entire network centrally. Centralized SDN generates high vulnerability against the Distributed Denial of Service (DDOS)....
										</p>
										<div class="date"><a href="https://doi.org/10.18517/ijaseit.13.2.17810">READ MORE</a></div>

									</div>
								</div>
							</div>

							<div class="clear"></div>
						</div>

					</div>

					<!--
						Skills
					-->
					<div class="content skills">

						<!-- title -->
						<div class="title">My Skills</div>
					
						<!-- content -->
						<div class="row">
						
							<!-- skill item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="skills-list">
									<div class="skill-title border-line-h">
										<div class="icon"><i class="fa fa-paint-brush"></i></div>
										<div class="name">General Skills</div>
									</div>
									<ul>
										<li class="border-line-h"> 
											<div class="name">Problem Solving</div>
											<div class="progress">
												<div class="percentage" style="width:95%;"></div>
											</div>
										</li>
										<li class="border-line-h"> 
											<div class="name">Communication</div>
											<div class="progress">
												<div class="percentage" style="width:85%;"></div>
											</div>
										</li>
										<li class="border-line-h"> 
											<div class="name">Leadership</div>
											<div class="progress">
												<div class="percentage" style="width:85%;"></div>
											</div>
										</li>
										<li>
											<div class="name">Management</div>
											<div class="progress">
												<div class="percentage" style="width:90%;"></div>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<!-- skill item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="skills-list dotted">
									<div class="skill-title border-line-h">
										<div class="icon"><i class="fa fa-flag"></i></div>
										<div class="name">Languages</div>
									</div>
									<ul>
										<li class="border-line-h"> 
											<div class="name">English</div>
											<div class="progress">
												<div class="percentage" style="width:90%;"></div>
											</div>
										</li>
										<li class="border-line-h"> 
											<div class="name">Indonesia</div>
											<div class="progress">
												<div class="percentage" style="width:100%;"></div>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<!-- skill item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="skills-list circles">
									<div class="skill-title border-line-h">
										<div class="icon"><i class="fa fa-code"></i></div>
										<div class="name">Coding</div>
									</div>
									<ul>
										<li> 
											<div class="name">PHP / MYSQL</div>
											<div class="progress p90">
												<span>90%</span>
											</div>
										</li>
										<li> 
											<div class="name">Go / Python</div>
											<div class="progress p75">
												<span>75%</span>
											</div>
										</li>
										<li> 
											<div class="name">Vue / JavaScript</div>
											<div class="progress p85">
												<span>85%</span>
											</div>
										</li>
										<li> 
											<div class="name">HTML / CSS</div>
											<div class="progress p95">
												<span>95%</span>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<!-- skill item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="skills-list list">
									<div class="skill-title border-line-h">
										<div class="icon"><i class="fa fa-list"></i></div>
										<div class="name">Knowledge</div>
									</div>
									<ul>
										<li> 
											<div class="name">Web development</div>
										</li>
										<li> 
											<div class="name">Mobile apps development</div>
										</li>
										<li> 
											<div class="name">Cloud services</div>
										</li>
										<li> 
											<div class="name">Mirco service architecture</div>
										</li>
										<li> 
											<div class="name">Modern and mobile-ready web</div>
										</li>
										<li> 
											<div class="name">Artificial Intelligence</div>
										</li>
										<li> 
											<div class="name">Deep Learning, Computer Vision</div>
										</li>
										<li> 
											<div class="name">SQL, NoSQL, Graph, Ontology</div>
										</li>
									</ul>
								</div>
							</div>
							
							<div class="clear"></div>
						</div>
						
					</div>

				</div>
			</div>
</template>

            
<script>
export default {
  name: "Resume",
  props: {
  }
};
</script>