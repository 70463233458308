<template>
<div class="card-inner animated active" id="about-card">
				<div class="card-wrap">

					<!-- 
						About 
					-->
					<div class="content about">

						<!-- title -->
						<div class="title">About Me</div>

						<!-- content -->
						<div class="row">
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="text-box">
									<p>
                                        I am a master's degree in Computer Science (data science and artificial intelligence). Loves to be a web developer and AI engineer. 
                                        I have rich experience in website development. Currently working as a Technical Lead for eCommerce in Games Studio company.
									</p>
								</div>
							</div>
							<div class="col col-d-6 col-t-6 col-m-12 border-line-v">
								<div class="info-list">
									<ul>
										<li><strong>Age . . . . .</strong> {{age}}</li>
										<li><strong>Residence . . . . .</strong> Indonesia</li>
										<li><strong>Freelance . . . . .</strong> Available</li>
										<li><strong>Address . . . . .</strong> Yogyakarta, ID</li>
									</ul>
								</div>
							</div>
							<div class="clear"></div>
						</div>

					</div>

					<!--
						Services
					-->
					<div class="content services">

						<!-- title -->
						<div class="title">My Services</div>

						<!-- content -->
						<div class="row service-items border-line-v">
							<!-- service item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-h">
								<div class="service-item">
									<div class="icon">
										<span class="fa fa-code"></span>
									</div>
									<div class="name">
										<span >
										Web Development								</span>
									</div>
									<div class="desc">
										<div >
											<p>Modern and mobile-ready website that will help you reach all of your curtomers.</p>
										</div>
									</div>
								</div>
							</div>
							<!-- service item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-h">
								<div class="service-item">
									<div class="icon">
										<span class="fa fa-microchip"></span>
									</div>
									<div class="name">
										<span >
										AI Application								</span>
									</div>
									<div class="desc">
										<div >
											<p>Various Artificial Intelligence application development to meet your needs for specific problems.</p>
										</div>
									</div>
								</div>
							</div>
							<!-- service item -->
							<div class="col col-d-6 col-t-6 col-m-12 border-line-h">
								<div class="service-item">
									<div class="icon">
										<span class="fa fa-mobile"></span>
									</div>
									<div class="name">
										<span >
										Mobile App								</span>
									</div>
									<div class="desc">
										<div >
											<p>Mobile app development services deliver custom mobile experiences for mobile device.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div class="clear"></div>

					</div>
					
					<!-- Quote -->
					<div class="content quote">

						<!-- content -->
						<div class="row">
							<div class="col col-d-12 col-t-12 col-m-12 border-line-v">
								<div class="revs-item">
									<div class="text">
										<div >
											The best way to predict your future is to create it.							
										</div>
									</div>
									<div class="user">
										<div class="info">
											<div class="name">Abraham Lincoln</div>
											<div class="company">16th U.S. president</div>
										</div>
										<div class="clear"></div>
									</div>
								</div>
							</div>
							<div class="clear"></div>
						</div>

					</div>

				</div>
			</div>
</template>

<script>
var moment = require('moment')
let fromTime = moment("1996-07-06").diff(moment(), "milliseconds")
let duration = moment.duration(fromTime)

export default {
  name: "About",
  props: {
  },
  data: function () {
    return {
      age: duration.years() /-1
    }
    
  },
};
</script>