<template>
<div class="card-started" id="home-card">

				<!--
					Profile
				-->
				<div class="profile no-photo">

					<!-- profile image -->
					<div class="slide" style="background-image: url(images/man6_big.jpg);"></div>

					<!-- profile titles -->
					<div class="title">Nadhir F Rozam</div>
					<!--<div class="subtitle">Web Designer</div>-->
					<div id="typed2" class="subtitle subtitle-typed">
						<div id="typing-title2" class="typing-title">
							<p>Developer</p>
							<p>Web Developer</p>
							<p>AI Engineer</p>
							<p>Freelancer</p>
						</div>
					</div>

					<!-- profile socials -->
					<div class="social">
						<a target="_blank" href="https://facebook.com/nadhir.fachrul.rozam"><span class="fa fa-facebook"></span></a>
						<a target="_blank" href="https://twitter.com/_rozam"><span class="fa fa-twitter"></span></a>
						<a target="_blank" href="https://instagram.com/nadhirfr"><span class="fa fa-instagram"></span></a>
						<a target="_blank" href="https://www.youtube.com/channel/UCbDeWRxwR_UrObYYuJmtXAA"><span class="fa fa-youtube"></span></a>
						<a target="_blank" href="https://www.linkedin.com/in/nadhirfr/"><span class="fa fa-linkedin"></span></a>
						<a target="_blank" href="https://github.com/nadhirfr"><span class="fa fa-github"></span></a>
						<a target="_blank" href="https://api.whatsapp.com/send?phone=6285643145332&text=Hello+Nadhir!"><span class="fa fa-whatsapp"></span></a>
					</div>
					
					<!-- profile buttons -->
					<div class="lnks">
						<a href="/Nadhir_Rozam-CV+Portofolio.pdf" target="_blank" class="lnk">
							<span class="text">Download CV</span>
							<span class="ion ion-archive"></span>
						</a>
						<a href="#" class="lnk discover next">
							<span class="text">Contact Me</span>
							<span class="arrow"></span>
						</a>
					</div>

				</div>

			</div>
</template>

            
<script>
export default {
  name: "Started",
  props: {
  }
};
</script>