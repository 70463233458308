import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VueTypedJs from 'vue-typed-js'

const app = createApp(App)
  .use(router)
  .use(VueTypedJs)
  .mount("#app");
