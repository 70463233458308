<template>
<div class="card-inner contacts" id="contacts-card">
				<div class="card-wrap">

					<!--
						Conacts Info
					-->
					<div class="content contacts">

						<!-- title -->
						<div class="title">Get in Touch</div>

						<!-- content -->
						<div class="row">
							<div class="col col-d-12 col-t-12 col-m-12 border-line-v">
								<!-- <div class="map" id="map"></div> -->
								<div class="info-list">
									<ul>
										<li><strong>Address . . .</strong> Yogyakarta, ID</li>
										<li><strong>Phone . . . . .</strong> +62 856 4314 5332</li>
										<li>
											<strong>Email . . .</strong>
											<div>admin@nadhir.net</div>
											<div>nadhir.rozam@gmail.com</div>
										</li>
									</ul>
								</div>
							</div>
							<div class="clear"></div>
						</div>

					</div>

					<!--
						Contact Form
					-->
					<div class="content contacts">

						<!-- title -->
						<div class="title">Contact Form</div>

						<!-- content -->
						<div class="row">
							<div class="col col-d-12 col-t-12 col-m-12 border-line-v">
								<div class="contact_form">
									<form id="cform" name="cform" @submit.prevent="sendEmail" method="post">
										<div class="row">
											<div class="col col-d-6 col-t-6 col-m-12">
												<div class="group-val">
													<input type="text" name="user_name" placeholder="Full Name" />
												</div>
											</div>
											<div class="col col-d-6 col-t-6 col-m-12">
												<div class="group-val">
													<input type="text" name="user_email" placeholder="Email Address" />
												</div>
											</div>
											<div class="col col-d-12 col-t-12 col-m-12">
												<div class="group-val">
													<textarea name="message" placeholder="Your Message"></textarea>
												</div>
											</div>
										</div>
										<div class="align-left">
											<input type="submit" class="button" value="Send">
										</div>
									</form>
									<div class="alert-success">
										<p>Thanks, your message is sent successfully.</p>
									</div>
								</div>
							</div>
							<div class="clear"></div>
						</div>

					</div>

				</div>
			</div>
</template>

            
<script>
import emailjs from 'emailjs-com';

export default {
  name: "Contact",
  props: {
  },
  methods: {
    sendEmail: (e) => {
		console.log("cform :"+$("#cform").valid())
		if ($("form").valid()){
			  emailjs.sendForm('gmail:nadhir.rozam', 'template_wa0wdv3', e.target, 'user_PkC0qWg6w9sgLGRtPzUH2')
			    .then((result) => {
			        console.log('SUCCESS!', result.status, result.text);
					$('#cform').fadeOut();
					$('.alert-success').delay(1000).fadeIn();
			    }, (error) => {
			        console.log('FAILED...', error);
			    });
		}
    }
  }
};
</script>